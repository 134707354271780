import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CancelPage } from "./cancel/cancel";
import { SuccessPage } from "./success/success";
import { SumpleHome } from "./sumplehome";

export const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<SumpleHome />} />
                <Route path="success" element={<SuccessPage />} />
                <Route path="cancel" element={<CancelPage />} />
            </Routes>
        </BrowserRouter>
    )
}