import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

export const SumpleHome = () => {
    useEffect(() => {
        document.title = 'サンプルホーム';
    });
    return (
        <>
            <div className='title'>
                <h1 className='titlefont'>SumpleHome</h1>
            </div>
        </>
    )
}