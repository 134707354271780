import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { RouterConfig } from './routerconfig';
import './index.css'

ReactDOM.render(
    <React.StrictMode>
        <RouterConfig />
    </React.StrictMode>,
    document.getElementById('root')
)