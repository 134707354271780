import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

export const SuccessPage = () => {
    useEffect(() => {
        document.title = '決済完了';
    });
    return (
        <>
            <div className='successtitle'>
                <h1 className='titlefont'>決済が完了しました</h1>
            </div>
            <div className='content'>
                <h2 className='desc'>ご購入いただきありがとうございます<br />
                    スマートフォンのホーム画面に戻り<br />「ダ・ヴィンチストア決済アプリ」をご確認ください</h2>
            </div>
        </>
    )
}