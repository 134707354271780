import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

export const CancelPage = () => {
    useEffect(() => {
        document.title = '決済失敗';
    });
    return (
        <>
            <div className='canceltitle'>
                <h1 className='titlefont'>決済に失敗しました</h1>
            </div>
            <div className='content'>
                <h2 className='desc'>申し訳ありません、お支払いがキャンセルされました<br />
                    再度、商品の購入をお試しください<br />
                    何度も失敗する場合、<a href="https://forms.gle/eebJ4c2QgaRxikTw8">お問い合わせ</a>をしてください</h2>
            </div>
        </>
    )
}